import { SettingsType, useGetSettingsByTypeQuery } from '@/api/settings';
import { SettingSwitch } from './SettingSwitch';

export const IsPizzeriaOpened = () => {
  const { data: isPizzeriaOpenedData } = useGetSettingsByTypeQuery({
    type: SettingsType.GENERAL,
    name: 'isPizzeriaOpened',
  });

  const { data: isDeliveryAvailableData } = useGetSettingsByTypeQuery({
    type: SettingsType.GENERAL,
    name: 'isDeliveryAvailable',
  });

  return (
    <div>
      {!!isPizzeriaOpenedData?.settings &&
        <SettingSwitch item={isPizzeriaOpenedData.settings[0]} title="Czy pizzeria przyjmuje zamówienia przez stronę?" tooltip="Wyłączyć przełącznik w przypadku np. dnia wolnego" />
      }

      {!!isDeliveryAvailableData?.settings &&
        <SettingSwitch item={isDeliveryAvailableData.settings[0]} title="Czy można składać zamówienia z dostawą?" tooltip="Wyłączyć przełącznik w dogodnym momencie" />
      }

      {/* {data?.settings.map((setting) => (
        <SettingSwitch key={setting.id} item={setting} />
      ))} */}
    </div>
  );
};
