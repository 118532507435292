import { Setting, useUpdateSettingMutation } from '@/api/settings';
import { Info } from 'lucide-react';
import { useState } from 'react';
import { ConfirmationModal } from '../ConfirmationModal';
import { Button } from '../ui/button';
import { Switch } from '../ui/switch';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';

type Props = {
  item: Setting;
  title: string;
  tooltip: string;
};

export const SettingSwitch = ({ item, title, tooltip }: Props) => {
  const [updateSetting] = useUpdateSettingMutation();
  const [checked, setChecked] = useState<boolean>(item.value === 'true');

  return (
    <div className="w-full max-w-100 px-10 pt-10 flex items-center gap-x-4">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <p>{title}</p>
            <Info className="w-3.5 h-3.5 ml-1 text-slate-400" />
          </TooltipTrigger>
          <TooltipContent>
            {tooltip}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <Switch
        checked={checked}
        onCheckedChange={(value) => setChecked(value)}
      />
      <ConfirmationModal
        title="Czy jesteś pewny?"
        description="Zmiana ustawienia spowoduje zmiany w systemie."
        onConfirm={() =>
          updateSetting({ settingId: item.id, value: checked.toString() })
        }
      >
        <Button className="bg-slate-900 text-white mt-4">Zapisz</Button>
      </ConfirmationModal>
    </div>
  );
};
